<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="dataTable.data"
      :max-items="dataTable.length"
      :total="dataTable.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.dataTable.start }} - {{ this.dataTable.end }} of
              {{ this.dataTable.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in dataTable.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <!-- <vs-th sort-key width="5%">No</vs-th> -->
        <vs-th sort-key="code">Tolerance Code</vs-th>
        <vs-th sort-key="note">Note</vs-th>
        <vs-th sort-key="valid_from">Valid From</vs-th>
        <vs-th sort-key="valid_to">Valid To</vs-th>
        <vs-th sort-key="created_by">Created By</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th>
        <vs-th sort-key="created_at">Created At</vs-th>
        <vs-th sort-key="updated_at">Updated At</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-no-wrap">
            <feather-icon
              v-if="i_tab == iTabActive || i_tab == iTabInActive || i_tab == iTabCanceled"
              title="View"
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current m-2"
              class="ml-2"
              @click.stop="handleView(indextr)"
            />
            <!-- v-if="i_tab == iTabDraft&&$store.getters['user/hasPermissions']('edit')" -->
            <feather-icon
              v-if="i_tab == iTabDraft"
              title="Edit"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current m-2"
              class="ml-2"
              @click.stop="handleEdit(indextr)"
            />
            <!-- v-if="i_tab == iTabDraft&&$store.getters['user/hasPermissions']('edit')" -->
            <feather-icon
              v-if="i_tab == iTabDraft"
              title="Cancel"
              icon="XCircleIcon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current m-2"
              @click.stop="handleCancel(indextr)"
            />
            <!-- v-if="i_tab == iTabDraft&&$store.getters['user/hasPermissions']('edit')" -->
            <feather-icon
              v-if="i_tab == iTabDraft"
              title="Activate"
              icon="CheckIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current m-2"
              class="ml-2"
              @click.stop="handleActivate(indextr)"
            />
            <!-- <feather-icon
              v-if="i_tab == iTabActive"
              title="Amend"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current m-2"
              class="ml-2"
              @click.stop="handleAmend(indextr)"
            /> -->
            <!-- v-if="i_tab == iTabActive&&$store.getters['user/hasPermissions']('edit')" -->
            <feather-icon
              v-if="i_tab == iTabActive"
              title="In-Activate"
              icon="XCircleIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current m-2"
              class="ml-2"
              @click.stop="handleInActivate(indextr)"
            />
          </vs-td>
          <!-- <vs-td :data="data[indextr].code">{{((length*page)-length )+ indextr+1}}</vs-td> -->
          <vs-td>{{ tr.code }}</vs-td>
          <vs-td>{{ tr.note }}</vs-td>
          <vs-td>{{ tr.valid_from | formatDate }}</vs-td>
          <vs-td>{{ tr.valid_to | formatDate }}</vs-td>
          <vs-td>{{ tr.created_by_name }}</vs-td>
          <vs-td>{{ tr.updated_by_name }}</vs-td>
          <vs-td>{{ tr.created_by_date | formatDate2 }}</vs-td>
          <vs-td>{{ tr.updated_by_date | formatDate2 }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="dataTable.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    dataTable: {
      type: Object,
    },
    customerCategoryId: {
      type: Number,
    },
    territorryId: {
      type: Number,
    },
    detail: {
      type: Boolean,
    },
    i_tab: {
      type: Number,
    },
  },
  data() {
    return {
      baseUrl: "/api/v1/master/customer",
      deleteId: null,
      iTabDraft: 1,
      iTabActive: 2,
      iTabInActive: 3,
      iTabCanceled: 4,
    };
  },
  methods: {
    handleSearch(search) {
      this.$emit("search", search);
    },
    handleChangePage(page) {
      this.$emit("change-page", page);
    },
    handleSort(key, active) {
      this.$emit("sort", key, active);
    },
    handleChangelength(val) {
      this.$emit("change-length", val);
    },
    handleClose() {
      this.$emit("close");
    },
    handleActivate(id) {
      this.$emit("activate", id);
    },
    handleInActivate(id) {
      this.$emit("in-activate", id);
    },
    handleAmend(id) {
      this.$emit("amend", id);
    },
    handleCancel(id) {
      this.$emit("cancel", id);
    },
    handleEdit(id) {
      this.$emit("edit", id);
    },
    handleView(id) {
      this.$emit("view", id);
    },
  },
  watch: {},
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    formatDate2(value) {
      if (value) {
        return moment(String(value)).add(-7, "hours").format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },
  mounted() {
    console.log("this.i_tab", this.i_tab);
    console.log("this.i_tab", this.iTabDraft);
    console.log("this.dataTable.tab", this.dataTable.tab);
    //this.$store.dispatch("user/getPermissions","tolerance");
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
